import Layout from "../components/layout";
import Image from "next/image";
import ImageBox from "../components/box/imageBox";
import BoxContainer from "../components/box/boxContainer";
import HighlightBox from "../components/box/highlightBox";
import IconHeaderDescriptionLinkBox from "../components/box/iconHeaderDescriptionLinkBox";
import IconHeaderDescriptionBox from "../components/box/iconHeaderDescriptionBox";
import Link from "next/link";

import TextTransition, { presets } from "react-text-transition";
import { useState, useEffect } from "react";

import { AiFillUnlock, AiFillHeart } from "react-icons/ai";
import {
  MdPayments,
  MdViewCompact,
  MdGppGood,
  MdContacts,
} from "react-icons/md";
import {
  HeroCenter,
  HeroLeft,
  HeroRight,
  HeroSection,
} from "../components/hero";
import CodeExamples from "../components/codeExamples/codeExamples";
import Head from "next/head";

const headerText = "Focus on launching your product" // Get to market faster -- Avoid tech debt from day 1
const subText = "Welcome to the workspace management platform you deserve. A platform that gets you launching faster."

function scrollToMore() {
  document.getElementById("firstSection").scrollIntoView({behavior: 'smooth'});
}

const securityBoxItems = [
  {
    heading: "User Identities",
    description:
      "We have invested thousands of hours to securly store and process user data on regional cloud providers so you can easily fulfill our compliance requirements.",
    icon: AiFillUnlock,
  },
  {
    heading: "API Endpoints",
    description:
      "We have experts working on the most secure authentication and authorization procedures. These can be implemented in minutes, independent if you are using SPA or SSR.",
    icon: MdViewCompact,
  },
  {
    heading: "Payment Information",
    description:
      "We've partnered up with Adyen and created a deep integration to the customer identities so you don't have to.",
    icon: MdPayments,
  },
];

const benefits = [
  {
    heading: "Time to Market",
    description:
      "We have invested thousands of hours into Backbrain, taking care of the essential pieces you need to build a successul product.",
  },
  {
    heading: "Maintenance",
    description:
      "Houston, we have no problems. With Backbrain, you can leave your workspace management to us. Never accumulate tech-debt on platform level again.",
  },
  {
    heading: "Focus on your product",
    description:
      "We support you all the way to the moon. You to focus on building a product rather than building up a technical foundation that get's you nowhere.",
  },
];

const solutionBoxItems = [
  {
    heading: "1 Day Integration",
    description: "Fast forward your product development by 2 years.",
    icon: AiFillUnlock,
  },
  {
    heading: "Designed For B2B Products",
    description: "Manage organizations and their users with ease.",
    icon: MdViewCompact,
  },
  {
    heading: "No Code Third Party Integrations",
    description: "From Payments to KYC. No &quot;glue&quot; code required.",
    icon: MdViewCompact,
  },
  {
    heading: "Schrems2 / GDPR Compliant",
    href: "/",
    description: "European providers with european hosting only.",
    icon: MdViewCompact,
  },
];

const linkBoxItems = [
  {
    heading: "Authentication",
    href: "/solutions/user-identity",
    description:
      "Authenticate your users against your application or allow them to use Personal Access Tokens or Service Accounts without implementing the logic behind it.",
    icon: AiFillUnlock,
  },
  {
    heading: "Authorization",
    href: "/solutions/user-identity",
    description:
      "Authorize your users with Backbrain's comprehensive, customizable, and user-friendly access control system, designed to keep your workspace secure and efficient.",
    icon: AiFillUnlock,
  },
  {
    heading: "Multi-Factor",
    href: "/solutions/user-identity",
    description:
      "Fortify your workspace with our multi-factor authentication, offering versatile options like authenticator apps and hardware dongles, ensuring protection for your users within your app.",
    icon: AiFillUnlock,
  },
]


const linkBoxItems2 = [
  {
    heading: "Access Management",
    href: "/",
    description:
      "From password authentication to Single Sign On and Multi Factor Authentication. Our Customer Identity solutions have you covered.",
    icon: AiFillUnlock,
  },
  {
    heading: "Backoffice",
    href: "/",
    description:
      "Managing users and organizations for your application is a crucial part. That's why we provide you with a customizable backoffice system.",
    icon: MdViewCompact,
  },
  {
    heading: "Self-Service",
    href: "/",
    description:
      "Your users should be able to manage their settings. From SSO and MFA to profile information or product specific settings. No frontend needed.",
    icon: MdContacts,
  },
  {
    heading: "Payments",
    href: "/",
    description:
      "Every SaaS needs payment. Tired of integrating Payment service providers on your own? With BackBrain it's just a few clicks to setup your payment.",
    icon: MdPayments,
  },
  {
    heading: "Compliance",
    href: "/",
    description:
      "Backbrain's system is designed to comply with different regulations. Users can choose where there data is stored. We use regional vendors for different use cases.",
    icon: MdGppGood,
  },

  {
    heading: "Customer Satisfaction",
    href: "/",
    description:
      "Great user experience comes with great user flows and an appropriate design. We've put hundreds of hours for each of our processes, so you don't have to.",
    icon: AiFillHeart,
  },
];

function Page({ page }) {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      1200 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <Layout>
      <Head>
        <title>Backbrain | Home</title>
      </Head>
      <HeroSection className="min-h-screen-10/10 drop-shadow-xl shadow-white">
        <HeroCenter className="mt-0">
          <div className="prose text-primary-content display:flex animate-fade-in-down -px-5">
            <h1 className="text-center text-neutral-content text-7xl md:text-8xl md:text-center mt-20 md:2-32 select-none">
              Focus on <span className="text-transparent bg-clip-text bg-gradient-to-br from-orange-500 to-pink-400 saturate-150">launching</span> your product
            </h1>
            <p className="text-base text-center text-lg md:text-xl -mt-5 text-gray-300">
              {subText}
            </p>
          </div>
        </HeroCenter>
        <HeroCenter className="animate-fade-in-down duration-1000">
          <div className="relative group">
            <div className="items-start justify-end gap-3 items-center md:flex-row md:py-0 md:flex">
              <div className="absolute -inset-1 bg-gradient-to-br from-pink-500 to-orange-400 rounded-full blur-sm opacity-0 group-hover:opacity-75 transition duration-1000 group-hover:duration-700 animation-tilt"></div>
              <button className="relative px-7 py-4 bg-gray-800/80 border-gray-800 border hover:border-transparent rounded-full leading-none flex items-center divide-x divide-gray-600 drop-shadow-xl" onClick={scrollToMore}>
                <span className="flex items-center space-x-5">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-pink-600 -rotate-6" fill="none" viewBox="0 0 24 24" stroke="white">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                  </svg>
                  <span className="pr-6 text-gray-100">We are currently in alpha testing</span>
                </span>
                <span className="pl-6 text-green-400 transition duration-200">Learn More</span>
              </button>
            </div>
          </div>
        </HeroCenter>
      </HeroSection>
      <div className="" id="firstSection">
        <HeroSection className="border-solid">
          <HeroCenter className="md:flex-row-reverse">
            <HeroRight className="basis-full md:basis-5/12 mt-12 hidden lg:block">
              <Image
                alt="No alt"
                layout="raw"
                src="/img/illustrations/workspace.svg"
                className="w-10/12"
                height="0.5"
                width="1"
              />
            </HeroRight>
            <HeroLeft className="">
              <div className="prose text-primary-content text-justify">
                <h1 className="text-transparent lg:text-7xl drop-shadow-xl bg-clip-text bg-gradient-to-br from-pink-500 to-orange-400 pb-3">Workspace Management</h1>
                <h3 className="text-base lg:text-lg text-white">
                  What is a workspace?
                </h3>
                <p className="text-base lg:text-lg antialiased">
                  A workspace serves as a central hub that links user accounts and manages access to the full range of your product&apos;s functionality.
                  <br/><br/>Backbrain enables users to manage their personal and team settings, providing granular control over access and permissions. Each user can customise their workspace to their individual needs and preferences, while administrators can define security settings for all team members.
                </p>
                <h3 className="text-base lg:text-lg text-white">
                  What can a workspace be used for?
                </h3>
                <p className="text-base lg:text-lg antialiased">
                  Backbrain workspaces give you the power to control your product from a single dashboard. Whether you&apos;re building a B2C product for single users and small teams or an enterprise-ready B2B product, workspaces help you manage access, billing and more.
                </p>
              </div>
            </HeroLeft>
          </HeroCenter>
        </HeroSection>
      </div>
      <div className="">
        <HeroSection className="border-solid">
          <HeroCenter className="md:flex-row-reverse">
            <HeroLeft className="">
              <div className="prose text-primary-content text-center">
                <h1 className="text-transparent lg:text-6xl drop-shadow-xl bg-clip-text bg-gradient-to-br from-pink-500 to-orange-400 pb-3">Benefits</h1>
              </div>
            </HeroLeft>
          </HeroCenter>
          <HeroCenter>
            <BoxContainer className="grid-cols-1 sm:grid-cols-3 -mt-12">
              {benefits.map((item) => (
                <IconHeaderDescriptionBox key={item.heading} item={item} />
              ))}
            </BoxContainer>
          </HeroCenter>
        </HeroSection>
      </div>
      <HeroSection className="">
        <HeroCenter className="md:flex-row-reverse">
          <HeroLeft className="basis-full md:basis-6/12 md:-ml-20">
            <div className="prose text-primary-content">
              <h1 className="text-transparent lg:text-7xl drop-shadow-xl bg-clip-text bg-gradient-to-br from-green-400 to-blue-600 pb-3">User Identity Management</h1>
              <p className="text-base lg:text-lg antialiased">
                With Backbrain you will be able to setup and manage how your users interact with your application in seconds. This includes powerful features like the most secure and reliable authentication, providing your users a secure and feature-rich experience.
              </p>
              <p className="text-base lg:text-lg antialiased">
                All these features are not just available right out of the box, but also continously maintained to comply to the latest web, mobile and security standards, staying on top of the latest trends and measures.
              </p>
              <p className="text-base lg:text-lg antialiased">
                Here is a short list of features that Backbrain supplies:
              </p>
            </div>
          </HeroLeft>
          <HeroRight className="basis-full md:basis-6/12 hidden md:block">
            <Image
              alt="No alt"
              layout="raw"
              src="/img/illustrations/payments1.svg"
              className="w-8/12"
              height="0.5"
              width="1"
            />
          </HeroRight>
        </HeroCenter>
        <HeroCenter className="md:flex-row-reverse">
          <BoxContainer className="grid-cols-1 sm:grid-cols-3">
            {linkBoxItems.map((item) => (
              <IconHeaderDescriptionLinkBox key={item.heading} item={item} />
            ))}
          </BoxContainer>
        </HeroCenter>
        <HeroCenter className="mt-10 md:-mb-20 -mb-21">
          <h1 className="text-center text-4xl md:text-center ">
            <span className="bg-transparent drop-shadow-xl text-transparent bg-clip-text bg-gradient-to-br from-white via-white to-gray-300">Get started in minutes.</span>
            <p className="text-center text-lg mt-3 text-gray-300">
              Here you can find a few code snippets to get started in minutes.
            </p>
          </h1>

        </HeroCenter>
        <HeroCenter className="">
          <CodeExamples />
        </HeroCenter>
      </HeroSection>
    </Layout>
  );
}

export default Page;
