import { classNames } from "../../lib/classNames";

function HighlightBox({ item, className }) {
  return (
    <div className={classNames(className, "rounded-lg ")}>
      <div className="flex justify-between items-center border-b gap-4 pb-4 mb-4">
        <h4 className="font-semibold text-primary mt-1 sm:text-lg md:text-xl">
          {item.heading}
        </h4>
        <span className="w-8 h-8 inline-flex justify-center items-center shrink-0 rounded-full bg-gray-300 text-primary dark:bg-transparent">
          <span>
            <item.icon className="h-7 w-7" />
          </span>
        </span>
      </div>
      <p className="text-neutral-content">{item.description}</p>
    </div>
  );
}

export default HighlightBox;
