import { classNames } from "../../lib/classNames";

function HeroCenter({ children, className }) {
  return (
    <div
      className={classNames(
        className,
        "max-w-screen-7xl 2xl:max-w-screen-2xl mx-auto pt-12 md:pt-24 pb-8 flex flex-wrap place-content-around center"
      )}
    >
      {children}
    </div>
  );
}

export default HeroCenter;
