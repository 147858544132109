const code = `async function lambdaHandler(event, context) {
  const identity = BackBrainIdentityContext(event.headers.Authorization)
    .validate();

  if (identity.allowed) {
    return {
      statusCode: 201,
      body: JSON.stringify(
        {
          message: "Blog post created",
        },
        null,
        2
      ),
    };
  }
}
`;
const highlightedLines = [2, 3, 4, 5, 7, 20];

export { code, highlightedLines };
