import { classNames } from "../../lib/classNames";
import Image from "next/image";

function ImageBox({ link }) {
  return (
    <div className="">
      <Image className="h-auto max-w-5xl rounded-2xl shadow-xl shadow-gray-700/30 opacity-80" src={link} alt="image description"></Image>
    </div>
  );
}

export default ImageBox;