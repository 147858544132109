import { classNames } from "../../lib/classNames";

function HeroSection({ children, className }) {
  return (
    <section
      className={classNames(
        className,
        `mx-auto ${className.includes("px-none") ? "" : "px-5"} py-10 gap-10`
      )}
    >
      {children}
    </section>
  );
}

export default HeroSection;
