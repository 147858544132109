import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrowNightBright } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { useState } from "react";

import {
  code as firstCode,
  highlightedLines as firstHighlightedLines,
} from "./languages/typescript/authenticate";

const LANGUAGE = {
  TYPESCRIPT_LAMBDA: {
    caption: "TypeScript / Lambda",
    id: "typescript",
  }
};

const STEPS = {
  AUTHENTICATE: {
    caption: "1. Authenticate",
    id: "authenticate",
  },
  AUTHORIZE: {
    caption: "2. Authorize",
    id: "authorize",
  },
  PAYMENT: {
    caption: "3. Check Feature Subscription",
    id: "payment",
  },
  CUSTOM_ATTRIBUTES: {
    caption: "4. Get Custom Attributes",
    id: "custom-attributes",
  },
};

function CodeExamples() {
  const [language, setLanguage] = useState(LANGUAGE.TYPESCRIPT_LAMBDA.id);
  const [step, setStep] = useState(STEPS.AUTHENTICATE.id);
  const [code, setCode] = useState(firstCode);
  const [highlightedLines, setHighlightedLines] = useState(
    firstHighlightedLines
  );

  const handleLanguageChange = async (e) => {
    e.preventDefault();
    await setLanguage(e.target.id);

    const data = await import(`./languages/${e.target.id}/${step}.js`);

    await setHighlightedLines(data.highlightedLines);
    await setCode(data.code);
  };

  const handleStepChange = async (e) => {
    e.preventDefault();
    await setStep(e.target.id);

    const data = await import(`./languages/${language}/${e.target.id}.js`);

    await setHighlightedLines(data.highlightedLines);
    await setCode(data.code);
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 bg-transparent pb-4">
        <div className="btn-group bg-transparent place-content-start w-full ">
          {Object.keys(LANGUAGE).map((key) => {
            return (
              <button
                key={LANGUAGE[key].id}
                onClick={handleLanguageChange}
                id={LANGUAGE[key].id}
                aria-label={LANGUAGE[key].caption}
                className={
                  language === LANGUAGE[key].id
                    ? "btn rounded-full drop-shadow-xl btn-active text-xs"
                    : "btn rounded text-xs"
                }
              >
                {LANGUAGE[key].caption}
              </button>
            );
          })}
        </div>
      </div>
      <div className="col-span-12 md:col-span-3 bg-transparent">
        <ul className="menu z-0 text-neutral text-xs md:text-sm lg:text-base w-full">
          {Object.keys(STEPS).map((key) => {
            return (
              <li key={STEPS[key].id}>
                <a
                  id={STEPS[key].id}
                  onClick={handleStepChange}
                  className={
                    step === STEPS[key].id
                      ? "text-white active"
                      : "text-white"
                  }
                >
                  {STEPS[key].caption}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="md:hidden col-span-12 border-b border-primary"></div>
      <div className="col-span-12 md:col-span-9">
          <SyntaxHighlighter
            language={language}
            wrapLongLines={false}
            style={tomorrowNightBright}
            customStyle={{ margin: "0px", backgroundColor:"#101827" }}
            showLineNumbers={true}
            lineProps={(lineNumber) => {
              let style = { display: "block" };
              if (highlightedLines.includes(lineNumber)) {
                style.backgroundColor = "#FFFFFF";
              }
              return { style };
            }}
          >
            {code}
          </SyntaxHighlighter>
      </div>
    </div>
  );
}

export default CodeExamples;
