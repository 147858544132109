import Link from "next/link";
import { AiOutlineArrowRight } from "react-icons/ai";

function IconHeaderDescriptionLinkBox({ item }) {
  return (
    <Link passHref href={item.href}>
      <div className="max-w-sm p-6 hover:text-white rounded-lg drop-shadow-xl bg-gray-800/80 border-gray-800 border hover:border-transparent border transition-all cursor-default hover:cursor-pointer hover:drop-shadow-xl hover:scale-105 duration-150">
        <div className="float-right mt-1 text-gray-300">
          <AiOutlineArrowRight className="h-7 w-7"></AiOutlineArrowRight>
        </div>
        <h5 className="mb-3 mt-1 text-2xl text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-blue-600 font-semibold tracking-tight">{item.heading}</h5>
        <p className="mb-2 font-light text-gray-300 antialiased">{item.description}</p>
      </div>
    </Link>
  );
}

export default IconHeaderDescriptionLinkBox;
