import Link from "next/link";

function IconHeaderDescriptionBox({ item }) {
  return (
    <div className="max-w-sm p-6 hover:text-white rounded-lg drop-shadow-xl bg-gray-800/50 border-gray-800 border transition-all cursor-default hover:drop-shadow-xl hover:scale-105 duration-150">
      <h5 className="mb-3 mt-1 text-2xl text-transparent bg-clip-text bg-gradient-to-br from-pink-500 to-orange-400 font-semibold tracking-tight">{item.heading}</h5>
      <p className="mb-2 font-light text-gray-300 antialiased">{item.description}</p>
    </div>
  );
}

export default IconHeaderDescriptionBox;
